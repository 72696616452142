export default {
  home: {
    home_search_placeholder: "搜尋",
    home_back_to_top: "回到頂部",
    home_reserved: "網站 @ 2021香港知專設計學院，職業訓練局。版權所有。",
    home_result_of: "搜尋結果",
    home_discover_more: "查看更多"
  },

  navbar: {
    navbar_discover: "探索",
    navbar_about: "關於我們",
    navbar_past_events: "已舉辦的活動",
    navbar_collection: "系列",
    navbar_contact: "聯絡",
    navbar_login: "登入",
    navbar_logout: "登出",
    navbar_profile: "個人"
  },

  about: {
    about_nav_about_us: "關於我們",
    about_nav_past_events: "已舉辦的活動",
    about_nav_acknowledgment: "Acknowledgment",
    about_nav_team: "Team",
    about_collaborators: "合作人员",
    about_credit: "鳴謝",
    about_discover: "查看更多"
  },

  single: {
    single_media_type: "種類",
    single_primary_elements: "要素",
    single_dimensions: "尺寸",
    single_year_of_release: "發行日期",
    single_add_to_collection: "加到我的系列",
    single_you_also_like: "你可能有興趣"
  },
  collection: {
    collection_title: "系列",
    collection_back: "返回",
    collection_media_type: "種類",
    collection_primary_elements: "要素",
    collection_dimensions: "尺寸",
    collection_year_of_release: "發行日期",
    collection_gridview: "Grid View",
    collection_edit_button: "修改",
    collection_remove_record: "移除",
    collection_update_button: "更新",
    collection_title_en: "標題 (英文)",
    collection_description_en: "簡介 (英文)",
    collection_title_tc: "標題 (中文)",
    collection_description_tc: "簡介 (中文)",
    collection_new_collecion: "系列名稱",
    collection_private_button: "私人",
    collection_create_collecion: "建立新系列",
    collection_new_collection_en_name: "需要填寫英文名稱",
    collection_new_collection_tc_name: "需要填寫中文名稱"
  },

  login: {
    login_title: "登入",
    login_button: "登入",
    login_email: "電郵",
    login_password: "密碼",
    login_register: "建立新帳戶",
    login_reset_password_title: "忘記密碼",
    login_reset_password_button: "重設密碼",
    login_reset_password_description:
      "系統會發送一封重置密碼的電子郵件至您郵箱。",
    login_error_invalid_email_pw: "無效的電郵和密碼",
    login_error_reset_email_not_found: "找不到電子郵件",
    login_error_reset_check_inbox: "請檢查您的電子郵件收件箱!"
  },
  reset: {
    reset_new_password: "新密碼",
    reset_description: "密碼長度必須至少為8個字符",
    reset_email: "電郵",
    reset_password: "密碼",
    reset_confirm_password: "確定密碼",
    reset_reset_button: "重設密碼",
    reset_error_valid_email: "請輸入有效的電子郵件地址",
    reset_error_enter_new_pw: "請輸入有效的新密碼",
    reset_password_not_match: "密碼和確認密碼不匹配"
  },
  account: {
    account_title: "會員",
    account_name: "名字",
    account_email: "電郵",
    account_password: "密碼",
    account_edit_account: "修改會員",
    account_my_collection: "我的系列",
    account_create_collection: "建立新的系列",
    account_edit_collection: "修改系列",
    account_remove_collection: "移除",
    account_first_name: "名",
    account_last_name: "姓",
    account_old_password: "舊密碼",
    account_new_password: "新密碼",
    account_confirm_new_password: "確定新密碼",
    account_update_button: "更新",
    account_old_pw_not_correct: "舊密碼不正確",
    account_password_matching: "密碼和確認密碼不匹配"
  },
  register: {
    register_email: "電郵",
    register_password: "密碼",
    register_confirm_password: "確定密碼",
    register_register_password: "建立"
  }
};
